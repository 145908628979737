define("discourse/plugins/chat/discourse/models/user-chat-thread-membership", ["exports", "@glimmer/tracking", "discourse/lib/notification-levels"], function (_exports, _tracking, _notificationLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserChatThreadMembership {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new UserChatThreadMembership(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "lastReadMessageId", [_tracking.tracked], function () {
      return null;
    }))();
    #lastReadMessageId = (() => (dt7948.i(this, "lastReadMessageId"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "notificationLevel", [_tracking.tracked], function () {
      return null;
    }))();
    #notificationLevel = (() => (dt7948.i(this, "notificationLevel"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "threadTitlePromptSeen", [_tracking.tracked], function () {
      return null;
    }))();
    #threadTitlePromptSeen = (() => (dt7948.i(this, "threadTitlePromptSeen"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.lastReadMessageId = args.last_read_message_id;
      this.notificationLevel = args.notification_level;
      this.threadTitlePromptSeen = args.thread_title_prompt_seen;
    }
    get isQuiet() {
      return this.notificationLevel === _notificationLevels.NotificationLevels.REGULAR || this.notificationLevel === _notificationLevels.NotificationLevels.MUTED;
    }
  }
  _exports.default = UserChatThreadMembership;
});
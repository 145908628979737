define("discourse/plugins/chat/discourse/models/user-chat-channel-membership", ["exports", "@glimmer/tracking", "discourse/models/user"], function (_exports, _tracking, _user3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserChatChannelMembership {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new UserChatChannelMembership(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "following", [_tracking.tracked], function () {
      return false;
    }))();
    #following = (() => (dt7948.i(this, "following"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "muted", [_tracking.tracked], function () {
      return false;
    }))();
    #muted = (() => (dt7948.i(this, "muted"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "desktopNotificationLevel", [_tracking.tracked], function () {
      return null;
    }))();
    #desktopNotificationLevel = (() => (dt7948.i(this, "desktopNotificationLevel"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "mobileNotificationLevel", [_tracking.tracked], function () {
      return null;
    }))();
    #mobileNotificationLevel = (() => (dt7948.i(this, "mobileNotificationLevel"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "lastReadMessageId", [_tracking.tracked], function () {
      return null;
    }))();
    #lastReadMessageId = (() => (dt7948.i(this, "lastReadMessageId"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "lastViewedAt", [_tracking.tracked], function () {
      return null;
    }))();
    #lastViewedAt = (() => (dt7948.i(this, "lastViewedAt"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "user", [_tracking.tracked], function () {
      return null;
    }))();
    #user = (() => (dt7948.i(this, "user"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.following = args.following;
      this.muted = args.muted;
      this.desktopNotificationLevel = args.desktop_notification_level;
      this.mobileNotificationLevel = args.mobile_notification_level;
      this.lastReadMessageId = args.last_read_message_id;
      this.lastViewedAt = new Date(args.last_viewed_at);
      this.user = this.#initUserModel(args.user);
    }
    #initUserModel(user) {
      if (!user || user instanceof _user3.default) {
        return user;
      }
      return _user3.default.create(user);
    }
  }
  _exports.default = UserChatChannelMembership;
});
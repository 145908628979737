define("discourse/plugins/chat/discourse/components/chat-footer", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/chat/footer/unread-indicator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _truthHelpers, _dButton, _concatClass, _i18n, _unreadIndicator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatFooter extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    get includeThreads() {
      if (!this.siteSettings.chat_threads_enabled) {
        return false;
      }
      return this.chatChannelsManager.hasThreadedChannels;
    }
    get directMessagesEnabled() {
      return this.chat.userCanAccessDirectMessages;
    }
    get shouldRenderFooter() {
      return this.chatStateManager.hasPreloadedChannels && (this.includeThreads || this.directMessagesEnabled);
    }
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRenderFooter}}
          <nav class="c-footer">
            <DButton
              @action={{fn @onClickTab "channels"}}
              @icon="comments"
              @translatedLabel={{i18n "chat.channel_list.title"}}
              aria-label={{i18n "chat.channel_list.aria_label"}}
              id="c-footer-channels"
              class={{concatClass
                "btn-transparent"
                "c-footer__item"
                (if (eq @activeTab "channels") "--active")
              }}
            >
              <UnreadChannelsIndicator />
            </DButton>
    
            {{#if this.directMessagesEnabled}}
              <DButton
                @action={{fn @onClickTab "direct-messages"}}
                @icon="users"
                @translatedLabel={{i18n "chat.direct_messages.title"}}
                aria-label={{i18n "chat.direct_messages.aria_label"}}
                id="c-footer-direct-messages"
                class={{concatClass
                  "btn-flat"
                  "c-footer__item"
                  (if (eq @activeTab "direct-messages") "--active")
                }}
              >
                <UnreadDirectMessagesIndicator />
              </DButton>
            {{/if}}
    
            {{#if this.includeThreads}}
              <DButton
                @action={{fn @onClickTab "threads"}}
                @icon="discourse-threads"
                @translatedLabel={{i18n "chat.my_threads.title"}}
                aria-label={{i18n "chat.my_threads.aria_label"}}
                id="c-footer-threads"
                class={{concatClass
                  "btn-flat"
                  "c-footer__item"
                  (if (eq @activeTab "threads") "--active")
                }}
              >
                <UnreadThreadsIndicator />
              </DButton>
            {{/if}}
          </nav>
        {{/if}}
      
    */
    {
      "id": "xkqHU2xE",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRenderFooter\"]],[[[1,\"      \"],[10,\"nav\"],[14,0,\"c-footer\"],[12],[1,\"\\n        \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.channel_list.aria_label\"],null]],[24,1,\"c-footer-channels\"],[16,0,[28,[32,2],[\"btn-transparent\",\"c-footer__item\",[52,[28,[32,3],[[30,1],\"channels\"],null],\"--active\"]],null]]],[[\"@action\",\"@icon\",\"@translatedLabel\"],[[28,[32,4],[[30,2],\"channels\"],null],\"comments\",[28,[32,1],[\"chat.channel_list.title\"],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,5],null,null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"directMessagesEnabled\"]],[[[1,\"          \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.direct_messages.aria_label\"],null]],[24,1,\"c-footer-direct-messages\"],[16,0,[28,[32,2],[\"btn-flat\",\"c-footer__item\",[52,[28,[32,3],[[30,1],\"direct-messages\"],null],\"--active\"]],null]]],[[\"@action\",\"@icon\",\"@translatedLabel\"],[[28,[32,4],[[30,2],\"direct-messages\"],null],\"users\",[28,[32,1],[\"chat.direct_messages.title\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,6],null,null,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"includeThreads\"]],[[[1,\"          \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.my_threads.aria_label\"],null]],[24,1,\"c-footer-threads\"],[16,0,[28,[32,2],[\"btn-flat\",\"c-footer__item\",[52,[28,[32,3],[[30,1],\"threads\"],null],\"--active\"]],null]]],[[\"@action\",\"@icon\",\"@translatedLabel\"],[[28,[32,4],[[30,2],\"threads\"],null],\"discourse-threads\",[28,[32,1],[\"chat.my_threads.title\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,7],null,null,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@activeTab\",\"@onClickTab\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-footer.js",
      "scope": () => [_dButton.default, _i18n.default, _concatClass.default, _truthHelpers.eq, _helper.fn, _unreadIndicator.UnreadChannelsIndicator, _unreadIndicator.UnreadDirectMessagesIndicator, _unreadIndicator.UnreadThreadsIndicator],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatFooter;
});
define("discourse/plugins/chat/discourse/templates/chat", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="chat-progress-bar-container"></div>
  
  {{#if this.chat.sidebarActive}}
    <div class="full-page-chat teams-sidebar-on">
      {{outlet}}
    </div>
  {{else}}
    <div
      class={{concat-class
        "full-page-chat"
        (if this.shouldUseCoreSidebar "full-page-chat-sidebar-enabled")
      }}
    >
      {{#if this.shouldUseChatSidebar}}
        <ChannelsList />
      {{/if}}
  
      <div
        id="main-chat-outlet"
        class={{concat-class "main-chat-outlet" this.mainOutletModifierClasses}}
      >
        {{outlet}}
        {{#if this.shouldUseChatFooter}}
          <ChatFooter
            @activeTab={{this.activeTab}}
            @onClickTab={{this.onClickTab}}
          />
        {{/if}}
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "LfkLQ2Gc",
    "block": "[[[10,0],[14,1,\"chat-progress-bar-container\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"chat\",\"sidebarActive\"]],[[[1,\"  \"],[10,0],[14,0,\"full-page-chat teams-sidebar-on\"],[12],[1,\"\\n    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[28,[37,3],[\"full-page-chat\",[52,[30,0,[\"shouldUseCoreSidebar\"]],\"full-page-chat-sidebar-enabled\"]],null]],[12],[1,\"\\n\"],[41,[30,0,[\"shouldUseChatSidebar\"]],[[[1,\"      \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,1,\"main-chat-outlet\"],[15,0,[28,[37,3],[\"main-chat-outlet\",[30,0,[\"mainOutletModifierClasses\"]]],null]],[12],[1,\"\\n      \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[41,[30,0,[\"shouldUseChatFooter\"]],[[[1,\"        \"],[8,[39,5],null,[[\"@activeTab\",\"@onClickTab\"],[[30,0,[\"activeTab\"]],[30,0,[\"onClickTab\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"component\",\"-outlet\",\"concat-class\",\"channels-list\",\"chat-footer\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat.hbs",
    "isStrictMode": false
  });
});
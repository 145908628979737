define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channels", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "truth-helpers", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/channels-list-direct", "discourse/plugins/chat/discourse/components/channels-list-public", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-footer", "discourse/plugins/chat/discourse/components/user-threads", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _truthHelpers, _i18n, _channelsListDirect, _channelsListPublic, _navbar, _chatFooter, _userThreads, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesChannels extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "activeTab", [_tracking.tracked], function () {
      return "channels";
    }))();
    #activeTab = (() => (dt7948.i(this, "activeTab"), void 0))();
    onClickTab(tab1) {
      this.activeTab = tab1;
    }
    static #_4 = (() => dt7948.n(this.prototype, "onClickTab", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
          <navbar.Title @title={{i18n "chat.heading"}} />
          <navbar.Actions as |a|>
            <a.ToggleDrawerButton />
            <a.FullPageButton />
            <a.CloseDrawerButton />
          </navbar.Actions>
        </Navbar>
    
        {{#if this.chatStateManager.isDrawerExpanded}}
          <div class="chat-drawer-content">
            {{#if (eq this.activeTab "channels")}}
              <ChannelsListPublic />
            {{else if (eq this.activeTab "direct-messages")}}
              <ChannelsListDirect />
            {{else if (eq this.activeTab "threads")}}
              <UserThreads />
            {{/if}}
          </div>
    
          <ChatFooter
            @activeTab={{this.activeTab}}
            @onClickTab={{this.onClickTab}}
          />
        {{/if}}
      
    */
    {
      "id": "qUl+p5hc",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[32,1],[\"chat.heading\"],null]]],null],[1,\"\\n      \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n\"],[41,[28,[32,2],[[30,0,[\"activeTab\"]],\"channels\"],null],[[[1,\"          \"],[8,[32,3],null,null,null],[1,\"\\n\"]],[]],[[[41,[28,[32,2],[[30,0,[\"activeTab\"]],\"direct-messages\"],null],[[[1,\"          \"],[8,[32,4],null,null,null],[1,\"\\n\"]],[]],[[[41,[28,[32,2],[[30,0,[\"activeTab\"]],\"threads\"],null],[[[1,\"          \"],[8,[32,5],null,null,null],[1,\"\\n        \"]],[]],null]],[]]]],[]]],[1,\"      \"],[13],[1,\"\\n\\n      \"],[8,[32,6],null,[[\"@activeTab\",\"@onClickTab\"],[[30,0,[\"activeTab\"]],[30,0,[\"onClickTab\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"navbar\",\"a\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channels.js",
      "scope": () => [_navbar.default, _i18n.default, _truthHelpers.eq, _channelsListPublic.default, _channelsListDirect.default, _userThreads.default, _chatFooter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesChannels;
});
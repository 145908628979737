define("discourse/plugins/chat/discourse/components/chat/drawer-routes/threads", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/i18n", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/user-threads", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _i18n, _discourseI18n, _navbar, _userThreads, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesThreads extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    backButtonTitle = (() => _discourseI18n.default.t("chat.return_to_list"))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
          <navbar.BackButton @title={{this.backButtonTitle}} />
          <navbar.Title
            @title={{i18n "chat.my_threads.title"}}
            @icon="discourse-threads"
          />
          <navbar.Actions as |action|>
            <action.ThreadsListButton />
            <action.ToggleDrawerButton />
            <action.FullPageButton />
            <action.CloseDrawerButton />
          </navbar.Actions>
        </Navbar>
    
        {{#if this.chatStateManager.isDrawerExpanded}}
          <div class="chat-drawer-content">
            <UserThreads />
          </div>
        {{/if}}
      
    */
    {
      "id": "wcpGfmq1",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"BackButton\"]],null,[[\"@title\"],[[30,0,[\"backButtonTitle\"]]]],null],[1,\"\\n      \"],[8,[30,1,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[28,[32,1],[\"chat.my_threads.title\"],null],\"discourse-threads\"]],null],[1,\"\\n      \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"ThreadsListButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n        \"],[8,[32,2],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"navbar\",\"action\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/threads.js",
      "scope": () => [_navbar.default, _i18n.default, _userThreads.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesThreads;
});